<template>
    <div v-show="!loading">
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="companiesEditForm"
            @submit="handleSubmit"
        >
            <b-card class="mt-2">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon icon="UserIcon" size="14"></feather-icon>
                        {{ $t("modules.people.profile") }}
                    </span>
                </div>

                <b-row class="mt-2">
                    <b-col cols="12" md="6">
                        <formulateInput
                            :label="$t('common.crud.name')"
                            name="name"
                            :placeholder="$t('common.crud.name')"
                            type="text"
                            validation="optional|max:200,length"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('common.status')"
                            name="status"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                statusOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.status = $event"
                        />
                    </b-col>

                    <b-col
                        v-if="isStatusInactive(formData.status)"
                        cols="12"
                        md="3"
                    >
                        <formulateInput
                            :label="$t('modules.people.inactivationReason')"
                            :placeholder="$t('common.crud.select')"
                            name="inactivationReason"
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="
                                inactivationReasonOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.inactivationReason = $event"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-1">
                    <b-col cols="12" md="6">
                        <formulateInput
                            :label="$t('modules.companies.tradingName')"
                            name="tradingName"
                            :placeholder="$t('modules.companies.tradingName')"
                            type="text"
                            validation="optional|max:200,length"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.companies.businessTaxId')"
                            name="businessTaxId"
                            :placeholder="$t('modules.companies.businessTaxId')"
                            type="text"
                            validation="optional|max:18,length"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.companies.businessSegment')"
                            name="businessSegment"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="
                                businessSegmentsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.businessSegment = $event"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="3">
                        <formulate-input
                            v-model="formData.foundationDate"
                            :label="$t('modules.companies.foundationDate')"
                            :placeholder="
                                $t('modules.companies.foundationDate')
                            "
                            name="foundationDate"
                            type="custom-date"
                            validation="optional|date:YYYY-MM-DD"
                            @update:date="formData.foundationDate = $event"
                        >
                        </formulate-input>
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.companies.websiteUrl')"
                            name="websiteUrl"
                            :placeholder="$t('modules.companies.websiteUrl')"
                            type="text"
                            validation="optional|url|max:255,length"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.companies.stateRegistration')"
                            name="stateRegistration"
                            :placeholder="
                                $t('modules.companies.stateRegistration')
                            "
                            type="text"
                            validation="optional|max:9,length"
                        />
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mt-1">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon icon="InfoIcon" size="14"></feather-icon>
                        {{ $t("modules.people.contactInformation") }}
                    </span>
                </div>

                <b-row>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.origin')"
                            name="origin"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="
                                originsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.origin = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.correspondence')"
                            name="correspondence"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                correspondencesOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.correspondence = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.newsLetter')"
                            name="newsletter"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                newslettersOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.newsletter = $event"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="d-flex justify-content-center">
                        <formulateErrors />
                    </b-col>
                </b-row>
            </b-card>

            <b-row class="justify-content-center align-self-center mt-2 ml-0">
                <formulateInput
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
                <formulateInput
                    :label="$t('common.crud.close')"
                    inputClass="btn btn-danger"
                    type="button"
                    class="pl-2"
                    @click="$emit('closeEditForm')"
                />
            </b-row>
        </formulateForm>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import { mapActions, mapMutations } from "vuex"
import Api from "@/services/api"
import _ from "lodash"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BRow,
        BCol,
        BCard,
    },
    props: {
        companyData: {
            required: true,
            type: Object,
        },
        classificationsOptions: {
            required: true,
            type: Array,
        },
        relationshipsOptions: {
            required: true,
            type: Array,
        },
        statusOptions: {
            required: true,
            type: Array,
        },
        originsOptions: {
            required: true,
            type: Array,
        },
        businessSegmentsOptions: {
            required: true,
            type: Array,
        },
        correspondencesOptions: {
            required: true,
            type: Array,
        },
        newslettersOptions: {
            required: true,
            type: Array,
        },
        inactivationReasonOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            formData: {
                name: "",
                businessTaxId: "",
                tradingName: "",
                websiteUrl: "",
                foundationDate: "",
                stateRegistration: "",
                statusReason: "",
                businessSegment: null,
                relationships: [],
                classifications: [],
                phones: [],
                emails: [],
                addresses: [],
                status: null,
                origin: null,
            },
            classifications: [],
            relationships: [],
            status: [],
            origins: [],
            businessSegments: [],
            updatedCompany: null,
            loading: true,
        }
    },
    mounted() {
        // Setting up initial values
        this.updatedCompany = this.companyData
        this.formData.name = this.companyData.name
        this.formData.businessTaxId = this.companyData.businessTaxId
        this.formData.stateRegistration = this.companyData.stateRegistration
        this.formData.tradingName = this.companyData.tradingName
        this.formData.websiteUrl = this.companyData.websiteUrl
        this.formData.statusReason = this.companyData.statusReason
        this.formData.foundationDate = this.companyData.foundationDate
        this.formData.relationships =
            (_.get(this.companyData, "relations") &&
                this.companyData.relations.map(el => el.id.toString())) ||
            []
        this.formData.classifications =
            (_.get(this.companyData, "classifications") &&
                this.companyData.classifications.map(el =>
                    el.id.toString()
                )) ||
            []
        this.formData.phones =
            (_.get(this.companyData, "phones") && this.companyData.phones) ||
            []
        this.formData.emails =
            (_.get(this.companyData, "emails") && this.companyData.emails) ||
            []
        this.formData.addresses =
            (_.get(this.companyData, "addresses") &&
                this.companyData.addresses) ||
            []
        this.formData.status =
            (_.get(this.companyData, "status.id") &&
                this.companyData.status.id.toString()) ||
            null
        this.formData.origin = _.get(this.companyData, "origin.id") || null
        this.formData.businessSegment =
            _.get(this.companyData, "businessSegment.id") || null
        this.formData.correspondence =
            _.get(this.companyData, "correspondence.id") || null
        this.formData.newsletter =
            _.get(this.companyData, "newsletter.id") || null
        this.formData.inactivationReason =
            _.get(this.companyData, "inactivationReason.id") || null

        this.loading = false
    },
    methods: {
        ...mapActions({
            getCompanyData: "companies/getCompanyById",
        }),
        ...mapMutations({
            SET_COMPANY: "companies/SET_COMPANY",
            UPDATE_COMPANY_PROPERTY: "companies/UPDATE_COMPANY_PROPERTY",
        }),

        isStatusInactive(statusId) {
            if (statusId === "2") return true

            this.formData.inactivationReason = ""
            return false
        },

        inactivateCompany() {
            if (
                this.formData.status === "2" &&
                _.get(this.updatedCompany, "status.id", false) !== "2"
            )
                this.$refs.statusReasonModal.$refs["status-reason"].show()
        },

        async handleSubmit() {
            try {
                const companiesRequest = {
                    id: this.companyData.id,
                    tradingName: this.formData.tradingName,
                    name: this.formData.name,
                    businessTaxId: this.formData.businessTaxId,
                    websiteUrl: this.formData.websiteUrl,
                    foundationDate: this.formData.foundationDate,
                    stateRegistration: this.formData.stateRegistration,
                    origin: this.formData.origin
                        ? { id: this.formData.origin }
                        : null,
                    businessSegment: this.formData.businessSegment
                        ? { id: this.formData.businessSegment }
                        : null,
                    status: this.formData.status
                        ? { id: this.formData.status }
                        : null,
                    inactivationReason: this.formData.inactivationReason
                        ? {
                              type: "inactivation-reasons",
                              id: this.formData.inactivationReason,
                          }
                        : null,
                    correspondence: this.formData.correspondence
                        ? {
                              type: "correspondences",
                              id: this.formData.correspondence,
                          }
                        : null,
                    newsletter: this.formData.newsletter
                        ? { type: "newsletters", id: this.formData.newsletter }
                        : null,
                    classifications:
                        this.formData.classifications.length > 0
                            ? this.formData.classifications.map(el => ({
                                  id: el,
                              }))
                            : [],
                    relations:
                        this.formData.relationships.length > 0
                            ? this.formData.relationships.map(el => ({
                                  id: el,
                              }))
                            : [],
                }

                await Api.update("companies", {
                    ...companiesRequest,
                    include:
                        "origin,status,relations,classifications,emails,phones,addresses,business-segments,correspondence,newsletter",
                })

                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation("companiesEditForm")
                this.updateCompany()
            } catch (e) {
                this.$formulate.handle(e, "companiesEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        updateCompany() {
            this.updatedCompany.tradingName = this.formData.tradingName
            this.updatedCompany.name = this.formData.name
            this.updatedCompany.businessTaxId = this.formData.businessTaxId
            this.updatedCompany.websiteUrl = this.formData.websiteUrl
            this.updatedCompany.foundationDate = this.formData.foundationDate
            this.updatedCompany.stateRegistration = this.formData.stateRegistration
            const [origin] = this.originsOptions.filter(
                option => option.id === this.formData.origin,
                this.formData.origin
            )
            const [businessSegment] = this.businessSegmentsOptions.filter(
                option => option.id === this.formData.businessSegment,
                this.formData.businessSegment
            )
            const [status] = this.statusOptions.filter(
                option => option.id === this.formData.status,
                this.formData.status
            )
            const [relationships] = this.relationshipsOptions.filter(
                option => this.formData.relationships.includes(option.id),
                this.formData.relationships
            )
            const [classifiactions] = this.classificationsOptions.filter(
                option => option.id === this.formData.businessSegment,
                this.formData.businessSegment
            )
            this.updatedCompany.emails = this.formData.emails
            this.updatedCompany.addresses = this.formData.addresses
            this.updatedCompany.phones = this.formData.phones
            this.updatedCompany.origin = origin
            this.updatedCompany.businessSegment = businessSegment
            this.updatedCompany.status = status
            this.updatedCompany.relations = relationships
            this.updatedCompany.classifications = classifiactions

            this.$emit("update:company", this.updatedCompany)
        },
    },
}
</script>

<style scoped></style>
